import classNames from "classnames";
import React from "react";
import { Letter } from "../models/Letter";

const typeClassNameMap: Record<Letter["type"], string> = {
  empty: "border-2 border-gray-200 bg-white",
  typed: "border-2 border-gray-200 bg-white text-gray-900",
  wrong: "bg-gray-400 text-white",
  misplaced: "bg-amber-400 text-white",
  correct: "bg-emerald-400 text-white",
};

const Cell: React.FC<CellProps> = ({ letter, small = false }) => (
  <div
    className={classNames(
      small ? "w-12 h-12 text-2xl" : "w-16 h-16 text-4xl",
      "flex items-center justify-center font-bold uppercase",
      "transition-colors duration-300 ease-in-out",
      typeClassNameMap[letter.type]
    )}
  >
    {letter.type === "empty" ? "" : letter.letter}
  </div>
);

Cell.displayName = "Cell";

Cell.propTypes = {};

export default React.memo(Cell);

export type CellProps = { letter: Letter; small?: boolean };
