import rawGuesses from "./guesses.txt";
import rawAnswers from "./answers.txt";
import { sample } from "lodash";

export const guesses = rawGuesses.split("\n");

export const answers = rawAnswers.split("\n");

export function isValidGuess(word: string): boolean {
  return answers.includes(word) || guesses.includes(word);
}

export function drawWord(): string {
  return sample(answers)!;
}
