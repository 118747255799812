import { Letter } from "./Letter";

export type KeyStatus = "unknown" | "wrong" | "misplaced" | "correct";

export function getKeyStatusFromLetters(
  letters: Letter[][],
  row: number
): Record<string, KeyStatus> {
  const statusMap: Record<string, KeyStatus> = {};
  for (let i = 0; i < row; i++) {
    for (let j = 0; j < 5; j++) {
      const l = letters[i][j];
      if (l.type === "empty" || l.type === "typed") {
        continue;
      } else {
        statusMap[l.letter] = l.type;
      }
    }
  }
  return statusMap;
}
