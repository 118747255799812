import { useCallback, useState } from "react";

const MESSAGE_LIMIT = 5;

export type Message = { time: number; content: string };

export default function useMessages(): [Message[], (content: string) => void] {
  const [messages, setMessages] = useState<Message[]>([]);

  const removeMessage = useCallback((time: number) => {
    setMessages((msgs) => msgs.filter((m) => m.time !== time));
  }, []);

  const pushMessage = useCallback((content: string) => {
    const time = Date.now();
    setMessages((messages) => {
      const message = { time, content };
      return (
        messages.length >= MESSAGE_LIMIT
          ? messages.slice(-MESSAGE_LIMIT + 1)
          : messages
      ).concat(message);
    });
    setTimeout(() => removeMessage(time), 3000);
  }, []);

  return [messages, pushMessage];
}
