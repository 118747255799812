import { useState } from "react";
import { GameState } from "../models/Game";

const STORE_VERSION_KEY = "storeVersion";
const STORE_VERSION = (0).toString();
const GAME_STATE_KEY = "gameState";

export function useGameState(): [GameState, (state: GameState) => void] {
  const [state, setState] = useState(() => {
    let loadedState = load();
    if (loadedState === null) {
      localStorage.setItem(STORE_VERSION_KEY, STORE_VERSION);
      loadedState = GameState.initialize();
      save(loadedState);
    }
    return loadedState;
  });

  return [
    state,
    (state: GameState): void => {
      save(state);
      setState(state);
    },
  ];

  function load(): GameState | null {
    const storeVersion = localStorage.getItem(STORE_VERSION_KEY);
    if (storeVersion === STORE_VERSION) {
      const gameStateJson = localStorage.getItem(GAME_STATE_KEY);
      if (typeof gameStateJson === "string") {
        try {
          const gameState = JSON.parse(gameStateJson);
          return GameState.is(gameState) ? gameState : null;
        } catch (_) {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  function save(state: GameState): void {
    localStorage.setItem(GAME_STATE_KEY, JSON.stringify(state));
  }
}
