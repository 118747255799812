import PropTypes from "prop-types";

export type Letter =
  | { type: "empty" }
  | { type: "typed"; letter: string }
  | { type: "wrong"; letter: string }
  | { type: "misplaced"; letter: string }
  | { type: "correct"; letter: string };

export const Letter = {
  empty: (): Letter => ({ type: "empty" }),
  typed: (letter: string): Letter => ({ type: "typed", letter }),
  wrong: (letter: string): Letter => ({ type: "wrong", letter }),
  misplaced: (letter: string): Letter => ({ type: "misplaced", letter }),
  correct: (letter: string): Letter => ({ type: "correct", letter }),
  create(rows: number, cols: number): Letter[][] {
    const letters: Letter[][] = [];
    for (let i = 0; i < rows; i++) {
      letters[i] = [];
      for (let j = 0; j < cols; j++) {
        letters[i][j] = Letter.empty();
      }
    }
    return letters;
  },
  update(
    letters: Letter[][],
    [row, col]: [number, number],
    letter: Letter
  ): Letter[][] {
    const newLetters: Letter[][] = [...letters];
    newLetters[row] = Array.from(letters[row]);
    newLetters[row][col] = letter;
    return newLetters;
  },
  updateRow(
    letters: Letter[][],
    row: number,
    lettersRow: Letter[]
  ): Letter[][] {
    const newLetters: Letter[][] = [...letters];
    newLetters[row] = lettersRow;
    return newLetters;
  },
};

export const letterPropType = PropTypes.oneOfType([
  PropTypes.shape({ type: PropTypes.oneOf(["empty"]).isRequired }).isRequired,
  PropTypes.shape({
    type: PropTypes.oneOf(["wrong"]).isRequired,
    letter: PropTypes.string.isRequired,
  }).isRequired,
  PropTypes.shape({
    type: PropTypes.oneOf(["misplaced"]).isRequired,
    letter: PropTypes.string.isRequired,
  }).isRequired,
  PropTypes.shape({
    type: PropTypes.oneOf(["correct"]).isRequired,
    letter: PropTypes.string.isRequired,
  }).isRequired,
]).isRequired;
