// import PropTypes from "prop-types";
import {
  IconChartBar,
  IconHelp,
  IconSettings,
  IconUserPlus,
} from "@tabler/icons";
import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import { useGameState } from "../hooks/useGameState";
import useMessages from "../hooks/useMessages";
import Game from "./Game";
import Help from "./Help";
import Messages from "./Messages";
import Settings from "./Settings";
import Statistics from "./Statistics";

const App: React.FC<AppProps> = () => {
  const [state, setState] = useGameState();
  const [messages, pushMessage] = useMessages();

  return (
    <div className="mt-4 max-w-[384px] mx-auto space-y-3">
      <header className="px-4 py-2 flex items-center rounded bg-gray-100 text-gray-800">
        <Link className="mr-2" to="/help">
          <IconHelp size={24} />
        </Link>
        <button
          className="mr-auto"
          onClick={() => pushMessage("Comming soon!")}
        >
          <IconUserPlus size={24} />
        </button>
        <Link className="text-xl font-medium" to="/">
          Wordle.app
        </Link>
        <Link className="ml-auto" to="/statistics">
          <IconChartBar size={24} />
        </Link>
        <Link className="ml-2" to="settings">
          <IconSettings size={24} />
        </Link>
      </header>
      <Routes>
        <Route
          path="/"
          element={
            <Game state={state} setState={setState} setMessage={pushMessage} />
          }
        />
        <Route path="/settings" element={<Settings />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/help" element={<Help />} />
      </Routes>
      <Messages messages={messages} />
    </div>
  );
};

App.displayName = "App";

App.propTypes = {};

export default App;

export type AppProps = {};
