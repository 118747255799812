import { IconArrowBack } from "@tabler/icons";
import React from "react";
import { Link } from "react-router-dom";

const Statistics: React.FC<{}> = () => (
  <>
    <section className="mt-4 p-4 flex flex-col bg-gray-100 rounded space-y-2">
      <header className="text-xl font-medium">Statistics</header>
      <main className="text-gray-700">Comming soon!</main>
    </section>
    <section className="mt-4 p-4 flex flex-col bg-gray-100 rounded space-y-2">
      <header className="text-xl font-medium">Guess Distribution</header>
      <main className="text-gray-700">Comming soon!</main>
    </section>
    <section className="mt-4 p-4 flex flex-col bg-gray-100 rounded space-y-2">
      <Link className="inline-flex items-center text-gray-700 text-lg" to="/">
        Back <IconArrowBack className="ml-0.5" size={18} />
      </Link>
    </section>
  </>
);

Statistics.displayName = "Help";

export default Statistics;
