import { IconRefresh, IconSearch, IconShare } from "@tabler/icons";
import React from "react";
import { GameState } from "../models/Game";
import { Letter } from "../models/Letter";
import Button from "./Button";

const Conclusion: React.FC<ConclusionProps> = ({
  state,
  setState,
  setMessage,
}) => {
  return (
    <div className="mt-4 p-4 bg-gray-100 rounded space-y-2">
      <main className="text-gray-800 text-lg">
        {state.status === "won"
          ? "Congratulations! You won!"
          : `You lost! The answer is ${state.answer}.`}
      </main>
      <footer className="justify-end flex items-center space-x-2">
        <Button
          className="bg-indigo-500 hover:bg-indigo-600 text-white font-medium"
          href={`https://www.lexico.com/search?utf8=✓&filter=en_dictionary&dictionary=en&query=${state.answer}`}
        >
          <IconSearch className="mr-1" size={16} /> Define
        </Button>
        <Button
          className="bg-blue-500 hover:bg-blue-600 text-white font-medium"
          onClick={onShare}
        >
          <IconShare className="mr-1" size={16} /> Share
        </Button>
        <Button
          className="bg-emerald-500 hover:bg-emerald-600 text-white font-medium"
          onClick={() => setState(GameState.initialize())}
        >
          <IconRefresh className="mr-1" size={16} /> Try Another!
        </Button>
      </footer>
    </div>
  );

  function onShare(): void {
    const data: ShareData = { text: makeShareText(state.letters) };
    if (
      typeof navigator.canShare !== "function" ||
      typeof navigator.share !== "function"
    ) {
      setMessage("Your browser does not support sharing.");
    }
    if (!navigator.canShare(data)) {
      setMessage("Your browser does not allow sharing.");
    }
    navigator.share(data);
  }
};

Conclusion.displayName = "Conclusion";

Conclusion.propTypes = {};

export default Conclusion;

export type ConclusionProps = {
  state: GameState;
  setState: (state: GameState) => void;
  setMessage: (message: string) => void;
};

function makeShareText(letters: Letter[][]): string {
  let numberOfGuesses = letters.findIndex((row) =>
    row.some((cell) => cell.type === "empty" || cell.type === "typed")
  );
  if (numberOfGuesses === -1) {
    numberOfGuesses = 6;
  }
  const lines: string[] = [`Wordle.app ${numberOfGuesses}/6`, ""];
  for (let i = 0; i < numberOfGuesses; i++) {
    lines.push(
      letters[i].map((cell) => letterTypeSymbolMap[cell.type]).join("")
    );
  }
  return lines.join("\n");
}

const letterTypeSymbolMap: Record<Letter["type"], string> = {
  empty: "⬛",
  typed: "⬛",
  wrong: "⬛",
  misplaced: "🟨",
  correct: "🟩",
};
