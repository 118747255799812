import { drawWord, isValidGuess } from "../data";
import { Letter } from "./Letter";
import times from "lodash/times";
import constant from "lodash/constant";

const NUM_LETTERS = 5;

export type GameStatus = "playing" | "won" | "lost";

export type GameState = {
  answer: string;
  status: GameStatus;
  letters: Letter[][];
  row: number;
  col: number;
};

export const GameState = {
  initialize: (): GameState => {
    return {
      answer: drawWord(),
      status: "playing",
      letters: Letter.create(6, 5),
      row: 0,
      col: 0,
    };
  },
  input: (state: GameState, letter: string): GameState => {
    if (state.status !== "playing") {
      return state;
    }
    if (state.col === state.answer.length) {
      throw new Error("You must press Enter to finish the word.");
    } else {
      return {
        ...state,
        letters: Letter.update(
          state.letters,
          [state.row, state.col],
          Letter.typed(letter)
        ),
        col: state.col + 1,
      };
    }
  },
  guess: (state: GameState): GameState => {
    if (state.status !== "playing") {
      return state;
    }
    if (state.col === state.answer.length) {
      const guess = state.letters[state.row]
        .map((cell) => (cell.type === "typed" ? cell.letter : ""))
        .join("");
      if (isValidGuess(guess)) {
        const letters = Letter.updateRow(
          state.letters,
          state.row,
          check(state.letters[state.row], state.answer)
        );
        if (guess === state.answer) {
          return { ...state, status: "won", letters };
        } else if (state.row + 1 === 6) {
          return { ...state, status: "lost", letters };
        } else {
          return { ...state, row: state.row + 1, col: 0, letters };
        }
      } else {
        throw new Error("This is not a valid guess.");
      }
    } else {
      throw new Error("You must type the entire word.");
    }
  },
  delete: (state: GameState): GameState => {
    if (state.status !== "playing") {
      return state;
    }
    if (0 < state.col && state.col <= state.answer.length) {
      return {
        ...state,
        letters: Letter.update(
          state.letters,
          [state.row, state.col - 1],
          Letter.empty()
        ),
        col: state.col - 1,
      };
    } else {
      return state;
    }
  },
  is: (object: unknown): object is GameState => {
    if (typeof object !== "object" || object === null) {
      return false;
    }
    return (
      typeof (object as Record<string, any>)["answer"] === "string" &&
      typeof (object as Record<string, any>)["status"] === "string" &&
      Array.isArray((object as Record<string, any>)["letters"]) &&
      typeof (object as Record<string, any>)["row"] === "number" &&
      typeof (object as Record<string, any>)["col"] === "number"
    );
  },
};

function check(typed: Letter[], answer: string): Letter[] {
  const matched = times(NUM_LETTERS, constant(false));
  const guess = typed.map((x) => {
    if (x.type === "typed") {
      return x.letter;
    }
    throw new Error("You must type the entire word.");
  });
  const result: Letter[] = Array(NUM_LETTERS);
  for (let i = 0; i < NUM_LETTERS; i++) {
    if (answer.charAt(i) === guess[i]) {
      matched[i] = true;
      result[i] = Letter.correct(guess[i]);
    }
  }
  for (let i = 0; i < NUM_LETTERS; i++) {
    if (result[i] !== undefined) {
      continue;
    }
    if (guess[i] === answer.charAt(i)) {
      matched[i] = true;
      result[i] = Letter.correct(guess[i]);
    } else {
      let at = -1;
      while ((at = answer.indexOf(guess[i], at + 1)) >= 0) {
        if (!matched[at]) {
          matched[at] = true;
          result[i] = Letter.misplaced(guess[i]);
          break;
        }
      }
      if (result[i] === undefined) {
        result[i] = Letter.wrong(guess[i]);
      }
    }
  }
  return result;
}
