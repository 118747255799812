import PropTypes from "prop-types";
import React from "react";
import { KeyStatus } from "../models/Key";
import Key from "./Key";

const layout = [
  Array.from("qwertyuiop"),
  Array.from("asdfghjkl"),
  ["Enter", ..."zxcvbnm", "Delete"],
];

const Keyboard: React.FC<KeyboardProps> = ({ onKeyDown, statusMap }) => {
  return (
    <div className="w-full space-y-1">
      {layout.map((row, rowIndex) => (
        <div
          key={rowIndex}
          className="grid gap-1.5"
          style={{ gridTemplateColumns: `repeat(${row.length}, 1fr)` }}
        >
          {Array.from(row).map((letter) => (
            <Key
              key={letter}
              displayText={letter}
              keyCode={letter}
              onKeyDown={onKeyDown}
              status={statusMap[letter] ?? "unknown"}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

Keyboard.displayName = "Keyboard";

Keyboard.propTypes = {
  onKeyDown: PropTypes.func,
  statusMap: PropTypes.object.isRequired as PropTypes.Validator<
    Record<string, KeyStatus>
  >,
};

export default Keyboard;

export type KeyboardProps = {
  onKeyDown?: (key: string) => void;
  statusMap: Record<string, KeyStatus>;
};
