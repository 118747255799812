import { IconArrowBack } from "@tabler/icons";
import React from "react";
import { Link } from "react-router-dom";
import { drawWord } from "../data";
import { Letter } from "../models/Letter";
import Cell from "./Cell";

const Help: React.FC<{}> = () => {
  const word1 = drawWord();
  const example1 = Array.from(word1).map((c, i) =>
    i === 0 ? Letter.correct(c) : Letter.wrong(c)
  );
  const word2 = drawWord();
  const example2 = Array.from(word2).map((c, i) =>
    i === 1 ? Letter.misplaced(c) : Letter.wrong(c)
  );
  return (
    <div className="prose mt-4 p-4 bg-gray-100 rounded">
      <p>
        This is a replica of{" "}
        <a
          href="https://www.nytimes.com/games/wordle/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          the Wordle game
        </a>{" "}
        developed by{" "}
        <a
          href="https://twitter.com/powerlanguish"
          target="_blank"
          rel="noopener noreferrer"
        >
          Josh Wardle
        </a>
        .
      </p>
      <p>
        Guess the <strong>WORDLE</strong> in six tries. Each guess must be a
        valid five-letter word. Hit the <kbd>Enter</kbd> button to submit.
      </p>
      <p>
        After each guess, the color of the tiles will change to show how close
        your guess was to the word.
      </p>
      <h3>Examples</h3>
      <div className="flex space-x-1">
        {example1.map((letter, i) => (
          <Cell key={i.toString()} letter={letter} small />
        ))}
      </div>
      <p>
        The letter <strong>{word1.charAt(0).toUpperCase()}</strong> is in the
        word and in the correct spot.
      </p>
      <div className="flex space-x-1">
        {example2.map((letter, i) => (
          <Cell key={i.toString()} letter={letter} small />
        ))}
      </div>
      <p>
        The letter <strong>{word2.charAt(1).toUpperCase()}</strong> is in the
        word but in the wrong spot.
      </p>
      <div className="flex space-x-1">
        {example3.map((letter, i) => (
          <Cell key={i.toString()} letter={letter} small />
        ))}
      </div>
      <p>
        The letter <strong>P</strong> is not in the word.
      </p>
      <Link className="inline-flex items-center" to="/">
        Back <IconArrowBack className="ml-0.5" size={18} />
      </Link>
    </div>
  );
};

Help.displayName = "Help";

export default Help;

const example3 = [
  Letter.correct("s"),
  Letter.correct("h"),
  Letter.correct("a"),
  Letter.wrong("p"),
  Letter.correct("e"),
];
