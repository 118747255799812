import React, { Children } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

const Button: React.FC<ButtonProps> = (props) => {
  const className = classNames(
    props.className,
    Children.count(props.children) === 1 ? null : "inline-flex items-center",
    "py-1 px-2 rounded",
    "transition-colors duration-150 ease-in-out"
  );
  if ("href" in props) {
    return (
      <a
        className={className}
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    );
  } else if ("to" in props) {
    return (
      <Link className={className} to={props.to}>
        {props.children}
      </Link>
    );
  } else {
    return (
      <button className={className} onClick={props.onClick}>
        {props.children}
      </button>
    );
  }
};

Button.propTypes = {};

export default Button;

export type ButtonProps = React.PropsWithChildren<{
  className?: string;
}> &
  (
    | {
        onClick?: (
          event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>
        ) => void;
      }
    | { href: string }
    | { to: string }
  );
