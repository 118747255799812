import React, { useEffect } from "react";
import { GameState } from "../models/Game";
import { getKeyStatusFromLetters } from "../models/Key";
import Cell from "./Cell";
import Keyboard from "./Keyboard";
import Conclusion from "./Conclusion";

const Game: React.FC<GameProps> = ({ state, setState, setMessage }) => {
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
    function handleKeyDown(e: KeyboardEvent): void {
      if (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) {
        return;
      }
      onKeyDown(e.key);
    }
  });

  return (
    <>
      <div className="bg-gray-100 p-4 rounded-md grid grid-cols-5 grid-rows-6 gap-2">
        {state.letters.map((rows, r) =>
          rows.map((cell, c) => <Cell key={`${r}-${c}`} letter={cell} />)
        )}
      </div>
      {state.status === "playing" ? (
        <Keyboard
          onKeyDown={onKeyDown}
          statusMap={getKeyStatusFromLetters(state.letters, state.row)}
        />
      ) : (
        <Conclusion state={state} setState={setState} setMessage={setMessage} />
      )}
    </>
  );

  function onKeyDown(ch: string): void {
    if (ch === "Backspace" || ch === "Delete") {
      setState(GameState.delete(state));
    } else if (ch === "Enter") {
      try {
        setState(GameState.guess(state));
      } catch (e) {
        setMessage((e as Error).message);
      }
    } else if (ch.match(/^[a-zA-Z]$/)) {
      try {
        setState(GameState.input(state, ch));
      } catch (e) {
        setMessage((e as Error).message);
      }
    }
  }
};

Game.displayName = "Game";

Game.propTypes = {};

export default Game;

export type GameProps = {
  state: GameState;
  setState: (state: GameState) => void;
  setMessage: (message: string) => void;
};
