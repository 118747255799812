import React from "react";
import PropTypes from "prop-types";
import { Message } from "../hooks/useMessages";

const Messages: React.FC<MessagesProps> = ({ messages }) => (
  <div className="fixed top-16 left-1/2 transform -translate-x-1/2 space-y-2">
    {messages.map((message) => (
      <article
        key={message.time}
        className="p-4 bg-gray-300 rounded opacity-80"
      >
        <header className="text-sm font-bold uppercase text-gray-600">
          Message
        </header>
        <main className="mt-1 text-gray-800">{message.content}</main>
      </article>
    ))}
  </div>
);

Messages.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

Messages.displayName = "Messages";

export default Messages;

export type MessagesProps = { messages: Message[] };
