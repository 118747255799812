import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { KeyStatus } from "../models/Key";

const statusClassNameMap: Record<KeyStatus, string> = {
  unknown: "bg-gray-200 hover:bg-gray-100 active:bg-gray-300",
  wrong:
    "text-gray-50 bg-gray-400 hover:text-gray-900 hover:bg-gray-300 active:bg-gray-500",
  misplaced: "bg-amber-300 hover:bg-amber-200 active:bg-amber-400",
  correct: "bg-emerald-300 hover:bg-emerald-200 active:bg-emerald-400",
};

const Key: React.FC<KeyProps> = ({
  onKeyDown,
  keyCode,
  displayText = keyCode,
  status,
}) => (
  <button
    className={classNames(
      "h-16 px-1 rounded uppercase select-none",
      statusClassNameMap[status],
      "focus:outline-1 focus:outline-blue-300",
      "transition-colors duration-150 ease-in-out",
      displayText.length > 1 ? "text-sm font-bold" : "text-lg font-medium"
    )}
    onClick={onKeyDown?.bind(null, keyCode)}
  >
    {displayText}
  </button>
);

Key.displayName = "Key";

Key.propTypes = {
  onKeyDown: PropTypes.func,
  displayText: PropTypes.string,
  keyCode: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    "unknown",
    "wrong",
    "misplaced",
    "correct",
  ] as KeyStatus[]).isRequired,
};

export default Key;

export type KeyProps = {
  onKeyDown?: (key: string) => void;
  displayText?: string;
  keyCode: string;
  status: KeyStatus;
};
