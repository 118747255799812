import { IconArrowBack } from "@tabler/icons";
import React from "react";
import { Link } from "react-router-dom";

const Settings: React.FC<{}> = () => (
  <div className="mt-4 p-4 flex flex-col bg-gray-100 rounded space-y-2">
    <button className="flex items-center text-lg text-left">
      <span className="mr-1 text-gray-700">Hard Mode</span>
      <span className="px-1 py-0.5 bg-gray-200 rounded-sm uppercase font-medium text-xs">
        comming soon
      </span>
    </button>
    <button className="flex items-center text-lg text-left">
      <span className="mr-1 text-gray-700">Dark Theme</span>
      <span className="px-1 py-0.5 bg-gray-200 rounded-sm uppercase font-medium text-xs">
        comming soon
      </span>
    </button>
    <button className="flex items-center text-lg text-left">
      <span className="mr-1 text-gray-700">High Contrast Mode</span>
      <span className="px-1 py-0.5 bg-gray-200 rounded-sm uppercase font-medium text-xs">
        comming soon
      </span>
    </button>
    <Link className="inline-flex items-center text-gray-700 text-lg" to="/">
      Back <IconArrowBack className="ml-0.5" size={18} />
    </Link>
  </div>
);

Settings.displayName = "Help";

export default Settings;
